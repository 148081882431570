"use strict";

const observerOptions = {
    root: null,
    threshold: 0.75,
    rootMargin: "100px",
};
const observer = new IntersectionObserver(observerCallback, observerOptions);

function observerCallback(entries, observer) {
    entries.forEach(entry => {
        if(entry.isIntersecting) {
            entry.target.classList.add('reveal');
            observer.unobserve(entry.target)
        }
    });
};

const storySections = document.querySelectorAll(".story-section");

storySections.forEach((storySection) => {
    if (storySection) {
        observer.observe(storySection);
    }
});
  