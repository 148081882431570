"use strict";

$(document).ready(function() {

    var gallery = $("#gallery");
    var galleryThumbs = $("#galleryThumbs");
    var syncedSecondary = true;

    gallery.owlCarousel({
        items: 1,
        slideSpeed: 500,
        nav: true,
        autoplay: false, 
        dots: false,
        loop: true,
        responsiveRefreshRate: 200,
        navText: ['<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #fff;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>', '<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #fff;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>'],
    }).on('changed.owl.carousel', galleryPosition);

    galleryThumbs.on('initialized.owl.carousel', function() {
        galleryThumbs.find(".owl-item").eq(0).addClass("current");
    }).owlCarousel({
            items: 9,
            dots: false,
            nav: false,
            slideSpeed: 500,
            slideBy: 1,
            margin: 16,
            responsive:{
                0: {
                    items: 3
                },
                768: {
                    items: 6
                },
                1200: {
                    items: 9
                }
            }
        }).on('changed.owl.carousel', galleryThumbsPosition);


    function galleryPosition(el) {
        //if you set loop to false, you have to restore this next line
        //var current = el.item.index;

        //if you disable loop you have to comment this block
        var count = el.item.count - 1;
        var current = Math.round(el.item.index - (el.item.count / 2) - .5);

        if (current < 0) {
            current = count;
        }
        if (current > count) {
            current = 0;
        }

        //end block

        galleryThumbs
            .find(".owl-item")
            .removeClass("current")
            .eq(current)
            .addClass("current");
        var onscreen = galleryThumbs.find('.owl-item.active').length - 1;
        var start = galleryThumbs.find('.owl-item.active').first().index();
        var end = galleryThumbs.find('.owl-item.active').last().index();

        if (current > end) {
            galleryThumbs.data('owl.carousel').to(current, 100, true);
        }
        if (current < start) {
            galleryThumbs.data('owl.carousel').to(current - onscreen, 100, true);
        }
    }

    function galleryThumbsPosition(el) {
        if (syncedSecondary) {
            var number = el.item.index;
            gallery.data('owl.carousel').to(number, 100, true);
        }
    }

    galleryThumbs.on("click", ".owl-item", function(e) {
        e.preventDefault();
        var number = $(this).index();
        gallery.data('owl.carousel').to(number, 300, true);
    });
});

