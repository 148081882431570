'use strict';

const introSectionObserver = new IntersectionObserver(introSectionObserverCallback, {
    root: null,
    threshold: 0,
    rootMargin: "-100px",
});

function introSectionObserverCallback(entries, introSectionObserver) {
    entries.forEach(entry => {
       
        if(entry.isIntersecting) {
            document.querySelector(".back-to-top").classList.add('show');
        }
    });
};

const introSection = document.querySelector(".body-section-project");

introSectionObserver.observe(introSection);
  

function backToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

var goTopBtn = document.querySelector('.back-to-top');
goTopBtn.addEventListener('click', backToTop);