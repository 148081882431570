"use scrict"

function formatTime(seconds) {
    minutes = Math.floor(seconds / 60);
    minutes = (minutes >= 10) ? minutes : "0" + minutes;
    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : "0" + seconds;
    return minutes + ":" + seconds;
}

window.addEventListener('load', function(event) {
    var audio = document.getElementById("audio");
    var playPause = document.getElementById("playPause");
    var mute = this.document.getElementById("mute");
    var playhead = document.getElementById("playhead"); 
    var time = document.getElementById("time"); 

    time.innerText = formatTime(audio.duration);

    playPause.addEventListener("click", function(){
        if (audio.paused) {
            audio.play();
            playPause.classList.remove("pause");
            playPause.classList.add("play");
        } else  {
            audio.pause();
            playPause.classList.remove("play");
            playPause.classList.add("pause");
        }
    });

    mute.addEventListener("click", function() {
        if(audio.muted) {
            audio.muted = false;
            mute.classList.remove("muted");
        } else {
            audio.muted = true;
            mute.classList.add("muted");
        }
    });

    audio.addEventListener("timeupdate", function(){ 
        var duration = this.duration; 
        var currentTime = this.currentTime; 
        var percentage = (currentTime / duration) * 100;
    
        time.innerText = formatTime(currentTime);
        playhead.style.width = percentage * 4 + 'px'; 
    });
}); 